.play-view {
  padding-top: 3rem;
  margin-top: 0;
  display: block;
}
.play-view .play-banner-wrapper {
  overflow: hidden;
  position: relative;
  display: block;
}
.play-view .play-banner-wrapper .play-banner-container {
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: auto;
}
@media screen and (min-width: 1024px) {
  .play-view .play-banner-wrapper .play-banner-container {
    max-width: 960px;
  }
}
@media screen and (min-width: 1216px) {
  .play-view .play-banner-wrapper .play-banner-container {
    max-width: 1152px;
  }
}
@media screen and (min-width: 1408px) {
  .play-view .play-banner-wrapper .play-banner-container {
    max-width: 1344px;
  }
}
.play-view .play-banner-wrapper .play-banner-container:after {
  content: "";
  display: block;
  width: 10rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: calc(100% + 2px);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0, transparent 50%);
}
.play-view .play-banner-wrapper .play-banner-container:before {
  content: "";
  display: block;
  width: 10rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: calc(100% + 2px);
  background: linear-gradient(to left, rgba(0, 0, 0, 0.65) 0, transparent 50%);
}
.play-view .play-banner-wrapper .play-banner-container .play-banner-poster {
  flex: none;
  width: 25%;
}
.play-view .play-banner-wrapper .play-banner-container .play-banner-poster figure {
  display: block;
  border-radius: 0 10px 10px 0;
  overflow: hidden;
  line-height: 0;
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
  aspect-ratio: auto 360/550;
}
.play-view .play-banner-wrapper .play-banner-container .play-banner-poster figure img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  min-width: 100%;
  max-width: 100%;
}
.play-view .play-banner-wrapper .play-banner-container .play-banner-poster figure:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.play-view .play-banner-wrapper .play-banner-container .play-banner-carousel {
  width: 73%;
  background-color: black;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
}

.play-info {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
@media screen and (min-width: 1024px) {
  .play-info {
    max-width: 960px;
  }
}
@media screen and (min-width: 1216px) {
  .play-info {
    max-width: 1152px;
  }
}
@media screen and (min-width: 1408px) {
  .play-info {
    max-width: 1344px;
  }
}
.play-info p {
  text-align: justify;
}
.play-info .play-datasheet {
  width: 25%;
  margin-top: 2.5rem;
}
.play-info .play-datasheet p {
  margin: 0;
}
.play-info .play-datasheet .text {
  display: grid;
  grid-row-gap: 20px;
}
.play-info .info {
  margin: 2.5rem 0;
  width: 73%;
  display: flex;
  flex-direction: column;
}
.play-info .info video {
  border-radius: 10px;
}
.play-info .info .trailer {
  display: flex;
  justify-content: center;
}
.play-info .play-synopsis .text {
  background-color: #e8b600;
  white-space: pre-wrap;
}
.play-info .text {
  padding: 30px;
  text-align: justify;
  border-radius: 10px;
}
.play-info h4 {
  margin: 0;
}



@media screen and (max-width: 767px) {
  .play-view {
    padding-top: 2rem;
  }
  .play-view .play-banner-wrapper .play-banner-container {
    flex-direction: column;
    border-left: none;
    border-right: none;
    flex-direction: row;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
  }
  .play-view .play-banner-wrapper .play-banner-container:after,
  .play-view .play-banner-wrapper .play-banner-container:before {
    display: none;
  }
  .play-view .play-banner-wrapper .play-banner-container .play-banner-poster {
    width: 100%;
  }
  .play-view .play-banner-wrapper .play-banner-container .play-banner-carousel {
    width: 100%;
    border-radius: 0;
  }

  .play-view .play-banner-wrapper .play-banner-container .play-banner-poster figure img {
    position: absolute;
    height: 90%;
    width: 90%;
    object-fit: cover;
    min-width: 90%;
    max-width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .play-info .play-datasheet p, .play-info h4 {
    text-align: center;
  }


  .play-info {
    flex-direction: column;
  }
  .play-info .play-datasheet,
  .play-info .info {
    width: 100%;
  }
  .play-info .play-datasheet {
    margin-top: 1.5rem;
  }
  .play-info .info {
    margin: 1.5rem 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .play-view {
    padding-top: 2.5rem;
  }
  .play-view .play-banner-wrapper .play-banner-container {
    max-width: 720px;
  }
  .play-view .play-banner-wrapper .play-banner-container .play-banner-poster {
    width: 30%;
  }
  .play-view .play-banner-wrapper .play-banner-container .play-banner-carousel {
    width: 68%;
    border-radius: 10px 0 0 10px;
  }

  .play-view .play-banner-wrapper .play-banner-container .play-banner-poster figure img {
    position: absolute;
    height: 80%;
    width: 80%;
    object-fit: cover;
    min-width: 100%;
    max-width: 100%;
  }

  .play-info {
    flex-direction: row;
  }
  .play-info .play-datasheet,
  .play-info .info {
    width: 48%;
  }
  .play-info .play-datasheet {
    margin-top: 2rem;
  }
  .play-info .info {
    margin: 2rem 0;
  }
}