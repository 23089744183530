.footer {
  background-color: #e8b600;
  padding: 0.5rem 0;
  text-align: center;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto;
  }

  .contact-info,
  .social-media,
  .copyright {
    flex: 1 0 auto;
  }

  .contact-info p {
    margin-bottom: 0.5rem;
  }

  .contact-info a,
  .copyright a {
    color: #333;
    text-decoration: none;
  }

  .contact-info a:hover,
  .copyright a:hover {
    color: #007bff;
  }

  .social-media {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .social-media a {
    margin-right: 1rem;
    font-size: 1.2rem;
    color: #333;
  }

  .social-media a:hover {
    color: #007bff;
  }

  .social-media i {
    transition: transform 0.2s ease-in-out;
  }

  .social-media a:hover i {
    transform: scale(1.1);
  }

  .copyright {
    margin-top: 1rem;
    font-size: 0.8rem;
  }

}


