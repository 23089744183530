/* Desktop styles */
.director-page {
  color: #F6EAD4;
  display: flex;
  flex-direction: column;
  background-color: black;
  justify-content: space-between;
}
.director-page p {
  text-align: justify;
  font-size: 1.4vw;
  font-weight: bold;
}
.director-page .section {
  margin: 0 auto;
  width: 85vw;
  display: flex;
  flex-direction: row;
}
.director-page h1 {
  margin-bottom: 0;
  text-decoration: underline;
  text-underline-offset: 25px;
  text-decoration-color: #e8b600;
}
.director-page h3 {
  margin-top: 0;
}
.director-page .name {
  display: flex;
  flex-direction: column;
}
.director-page .info-column {
  justify-content: center;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.director-page .info-column h1 {
  font-size: 5vw;
  font-family: "Koulen", serif;
}
.director-page .director-img, .director-page .director-img2 {
  aspect-ratio: 1;
  width: 40%;
  height: auto;
  overflow: hidden;
}
.director-page .director-img img, .director-page .director-img2 img{
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/* Tablet styles */
@media (max-width: 768px) {
  .director-page .section {
    flex-direction: column;
    width: 90vw;
  }
  .director-page .info-column h1 {
    font-size: 6vw;
  }
  .director-page .director-img {
    width: 100%;
  }
  .director-page p {
    text-align: justify;
    font-size: 2vw;
    font-weight: bold;
  }
  .director-page .director-img2 img{
    display: none;
  }
}

/* Phone styles */
@media (max-width: 480px) {
  .director-page p {
    font-size: 3vw;
    margin: 20px;
  }
  .director-page .section {
    flex-direction: column;
    width: 95vw;
  }

  .director-page h1 {
    text-underline-offset: 10px;
  }

  .director-page .info-column h1 {
    font-size: 8vw;
    text-align: center;
    padding: 0 10px;
  }
  .director-page .info-column h3 {
    font-size: 5vw;
    text-align: center;
  }
  .director-page .director-img {
    width: 100%;
    text-align: center;
  }
  .director-page .director-img2 img{
    display: none;
  }
}
