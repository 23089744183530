.news-view {
  margin: 0 auto;
}
@media screen and (min-width: 1024px) {
  .news-view {
    width: 960px;
  }
}
@media screen and (min-width: 1216px) {
  .news-view {
    width: 1152px;
  }
}
@media screen and (min-width: 1408px) {
  .news-view {
    width: 1344px;
  }
}
.news-view h1 {
  background-color: #291100;
  color: #e8b600;
  font-family: "Koulen", serif;
  align-self: center;
  text-align: center;
  padding: 10px;
}


.news-view img {
  aspect-ratio: 188/157;
  object-fit: contain;
  max-width: 25vw;
  border-radius: 10px;
  box-shadow: black 3px 3px 10px;
}

.news-view .news-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.news-view .news-header .information {
  flex: 1;
}
.news-view .news-header h2 {
  text-transform: uppercase;
  text-align: center;
}
.news-view .news-header h4 {
  font-size: 20px;
  text-align: center;
}
.news-view .news-content {
  display: flex;
  flex-direction: column;
}
.news-view .news-content p {
  white-space: pre-wrap;
  text-align: justify;
  padding: 1rem;
}
.news-view .news-content .media-content {
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  column-gap: 2rem;
  row-gap: 2rem;
}
.news-view .news-content .media-content img {
  aspect-ratio: inherit;
  max-width: 20vw;
  background-color: black;
}
.news-view .news-content .media-content video {
  border-radius: 10px;
  max-width: 60%;
}
@media screen and (max-width: 1023px) {
  .news-view {
    width: 90%;
  }
  .news-view img {
    max-width: 50vw;
  }
  .news-view .news-content .media-content img {
    max-width: 80vw;
    aspect-ratio: inherit;
  }
  .news-view .news-content .media-content video {
    max-width: 80%;
  }
  .news-view .news-header {
    flex-direction: column-reverse;
  }
  .news-view .news-header h4 {
    font-size: 30px;
  }
}

@media screen and (max-width: 767px) {
  .news-view {
    width: 100%;
  }
  .news-view img {
    max-width: 75vw;
  }
  .news-view .news-content .media-content img {
    max-width: 80vw;
    aspect-ratio: 1;
  }
  .news-view .news-content .media-content video {
    max-width: 100%;
  }
  .news-view .news-header {
    flex-direction: column-reverse;
  }
  .news-view .news-header h4 {
    font-size: 25px;
  }
}
