.talleres-page {
  margin: 20px auto;
}
@media screen and (min-width: 1024px) {
  .talleres-page {
    width: 960px;
  }
}
@media screen and (min-width: 1216px) {
  .talleres-page {
    width: 1152px;
  }
}
@media screen and (min-width: 1408px) {
  .talleres-page {
    width: 1344px;
  }
}
.talleres-page a {
  text-decoration: none;
  color: inherit;
}
.talleres-page .title-ban {
  background-color: #291100;
  color: #e8b600;
  font-family: "Koulen", serif;
  align-self: center;
  text-align: center;
  padding: 10px;
}
.talleres-page .claves-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.talleres-page .clave-item {
  flex: 0 0 25%;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: black;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
}
.talleres-page .clave-item h1 {
  margin: 0 0 10px;
  text-align: center;
}
.talleres-page .clave-item p {
  text-align: justify;
  margin: 0;
}
.talleres-page .clave-item img {
  border-radius: 10px;
  object-fit: contain;
  width: 90%;
  margin-bottom: 30px;
}
.talleres-page .top-images {
  height: 7rem;
  width: 100%;
  border-radius: 5px;
  background-color: black;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
}
.talleres-page .top-images img {
  aspect-ratio: 3/4;
  object-fit: cover;
  flex: 10%;
  padding: 5px;
}
.talleres-page .taller-item {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: white;
}
.talleres-page .taller-item p {
  background-color: #291100;
  border-radius: 10px;
  padding: 1.5rem;
}
.talleres-page .taller-item img {
  aspect-ratio: 1;
  object-fit: cover;
  width: 250px;
  border: #291100 solid 10px;
  border-radius: 100%;
}
.talleres-page .taller-item a {
  background-color: #291100;
  border-radius: 10px;
  padding: 1.5rem;
}
.talleres-page .taller-item h1, .talleres-page .taller-item h5 {
  padding: 0;
  margin: 0;
}
.talleres-page .taller-item h5 {
  margin-top: 10px;
}


@media screen and (max-width: 767px) {
  .talleres-page {
    margin: 10px;
  }
  .talleres-page .clave-item {
    flex: 0 0 100%;
    margin: 5px 0;
  }
  .talleres-page .top-images {
    height: 5rem;
  }
  .talleres-page .top-images img {
    flex: 20%;
  }
  .talleres-page .taller-item {
    flex-direction: column;
  }
  .talleres-page .taller-item:first-child {
    flex-direction: column-reverse;
  }
  .talleres-page .taller-item img {
    width: 250px;
    margin: 10px 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .talleres-page {
    margin: 15px auto;
  }
  .talleres-page .clave-item {
    flex: 0 0 50%;
  }
  .talleres-page .top-images {
    height: 6rem;
  }
  .talleres-page .top-images img {
    flex: 15%;
  }
  .talleres-page .taller-item {
    flex-direction: row;
  }
  .talleres-page .taller-item img {
    width: 200px;
  }
}