.creaciones-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  h1{
    background-color: #291100;
    color: #e8b600;
    font-family: "Koulen", serif;


    @media screen and (min-width: 1024px){
      width: 960px;
    }
    @media screen and (min-width: 1216px){
      width: 1152px;
    }
    @media screen and (min-width: 1408px){
      width: 1344px;
    }

    align-self: center;
    text-align: center;
    padding: 10px;

  }
}

.card-container{
  @media screen and (min-width: 1024px){
    max-width: 960px;
  }
  @media screen and (min-width: 1216px){
    max-width: 1152px;
  }
  @media screen and (min-width: 1408px){
    max-width: 1344px;
  }
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}