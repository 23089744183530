/* Default styles for mobile */
.grupo-page {
  flex-direction: column;
  margin: 1rem auto;
  width: 100%;
}
.grupo-page #Title {
  background-color: #291100;
  color: #e8b600;
  font-family: "Koulen", serif;
  align-self: center;
  text-align: center;
  padding: 10px;
}
.grupo-page #paragraph {
  text-align: center;
  font-size: 4vw;
  font-weight: bold;
  margin: 0 20px;
}
.grupo-page .cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.grupo-page .person-item {
  transition: all 0.1s;
  flex-shrink: 0;
  width: 100%;
  text-align: center;
}
.grupo-page .person-item .content {
  padding: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.grupo-page .person-item .content img {
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
}
.grupo-page .person-item .content h1 {
  font-size: 4vw;
  margin: 5px 0;
  text-transform: uppercase;
}
.grupo-page .person-item .content h4 {
  margin: 3px 0;
  font-size: 3vw;
  font-weight: normal;
}

/* Tablet styles */
@media screen and (min-width: 768px) {
  .grupo-page {
    flex-direction: row;
    width: 90%;
  }
  .grupo-page .person-item {
    width: 50%;
  }
  .grupo-page #paragraph {
    font-size: 2vw;
  }
  .grupo-page .person-item .content h1 {
    font-size: 2vw;
  }
  .grupo-page .person-item .content h4 {
    font-size: 1.5vw;
  }
}

/* Desktop styles */
@media screen and (min-width: 1024px) {
  .grupo-page {
    width: 960px;
  }
  .grupo-page .person-item {
    width: 25%;
  }
  .grupo-page #paragraph {
    font-size: 1.2vw;
  }
  .grupo-page .person-item .content h1 {
    font-size: 1.2vw;
  }
  .grupo-page .person-item .content h4 {
    font-size: 1vw;
  }
}

@media screen and (min-width: 1216px) {
  .grupo-page {
    width: 1152px;
  }
}

@media screen and (min-width: 1408px) {
  .grupo-page {
    width: 1344px;
  }
}
