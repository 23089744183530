#error-page {
  background-color: #e8b600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;
  align-items: center;
  flex: 1;
  height: 100vh;
}
#error-page h1, #error-page h2 {
  font-family: "Koulen", sans-serif;
}
#error-page p {
  font-family: "Lexend", serif;
  font-weight: bold;
}
#error-page a {
  background-color: #291100;
  padding: 10px;
  text-decoration: none;
  color: #e8b600;
  border-radius: 5px;
}

