.sede-page {
  margin: 0 auto;
}
@media screen and (min-width: 1024px) {
  .sede-page {
    width: 960px;
  }
}
@media screen and (min-width: 1216px) {
  .sede-page {
    width: 1152px;
  }
}
@media screen and (min-width: 1408px) {
  .sede-page {
    width: 1344px;
  }
}
@media screen and (max-width: 1023px) {
  .sede-page {
    width: 100%;
  }
  .sede-page .info-columns {
    flex-direction: column;
  }
  .sede-page .column-item {
    width: 100%;
    margin: 10px 0;
  }
}
@media screen and (max-width: 767px) {
  .sede-page h1 {
    font-size: 1.5em;
  }
  .sede-page .column-item {
    padding: 10px;
  }
  .sede-page .column-item img {
    width: 100%;
  }
}
.sede-page .info-columns {
  display: flex;
  align-items: center;
}
.sede-page h1 {
  background-color: #291100;
  color: #e8b600;
  font-family: "Koulen", serif;
  align-self: center;
  text-align: center;
  padding: 10px;
}
.sede-page .left-info {
  justify-content: space-evenly;
  text-align: center;
  background: rgb(140, 96, 49);
  background: linear-gradient(0deg, rgb(140, 96, 49) 0%, rgb(111, 65, 32) 100%);
}
.sede-page .left-info img {
  margin: 0 !important;
}
.sede-page p {
  margin: 0;
}
.sede-page .column-item {
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  font-family: "Lexend", serif;
  text-align: justify;
  aspect-ratio: 4/7;
  display: flex;
  flex-direction: column;
  background-color: #b7b7b7;
  width: 33.3%;
  margin: 10px;
  padding: 20px;
}
.sede-page .column-item img {
  width: 70%;
  aspect-ratio: 1;
  object-fit: cover;
  margin: auto 0;
  border-radius: 10px;
}

@media screen and (max-width: 1023px) {
  .sede-page .info-columns {
    flex-direction: column;
  }
  .sede-page .column-item {
    width: 80%;
    margin: 10px 0;
    aspect-ratio: auto;

  }
}

@media screen and (max-width: 767px) {
  .sede-page h1 {
    font-size: 1.5em;
  }
  .sede-page .column-item {
    padding: 10px;
  }
  .sede-page .column-item img {
    width: 100%;
  }
}

