.sede-page{
  margin: 0 auto;
  @media screen and (min-width: 1024px) {
    width: 960px;
  }
  @media screen and (min-width: 1216px) {
    width: 1152px;
  }
  @media screen and (min-width: 1408px) {
    width: 1344px;
  }

  .info-columns{
    display: flex;
  }
  h1 {
    background-color: #291100;
    color: #e8b600;
    font-family: "Koulen", serif;
    align-self: center;
    text-align: center;
    padding: 10px;
  }
  .left-info{
    justify-content: space-evenly;
    text-align: center;
    background: rgb(140,96,49);
    background: linear-gradient(0deg, rgba(140,96,49,1) 0%, rgba(111,65,32,1) 100%);
    img{
      margin: 0 !important;
    }
  }
  p{
    margin: 0;
  }
  .column-item{
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    font-family: "Lexend", serif;
    text-align: justify;
    aspect-ratio: 4/7;
    display: flex;
    flex-direction: column;
    background-color: #b7b7b7;
    width: 33.3%;
    margin: 10px;
    padding: 20px;
    img{
      width: 70%;
      aspect-ratio: 1;
      object-fit: cover;
      margin: auto 0;
      border-radius: 10px;
    }
  }
}