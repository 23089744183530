.nosotros-page{
  display: flex;
  margin: 30px auto;
  @media screen and (min-width: 1024px){
    width: 960px;
  }
  @media screen and (min-width: 1216px){
    width: 1152px;
  }
  @media screen and (min-width: 1408px){
    width: 1344px;
  }
  .info-banner{

    width: 50%;

    display: flex;
    align-items: center;
    .info-photo{
      margin: 20px;
    }

    img{
      width: 100%;
      aspect-ratio: 1;
      border-radius: 10px;
      object-fit: cover;

    }
  }
  .info-column{

  }
}