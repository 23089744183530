.alt-effect:hover {
  transform: scale(1.1) !important;
  box-shadow: rgb(255, 0, 0) 0 0 5px 2px;
}
.alt-effect:hover .play-photo {
  overflow: hidden;
}
.alt-effect:hover .play-photo img {
  transform: scale(1) !important;
}

.calendario-page .play-photo {
  aspect-ratio: inherit !important;
}

.play-card-itemEvents {
  width: 33.33%;
  flex-grow: 0;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: start;
  font-family: Lexend, serif;
}
.play-card-itemEvents #date {
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: normal;
}
.play-card-itemEvents .more-button {
  color: black;
  text-decoration: none;
  border-radius: 10px;
  transition: 0.2s;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.play-card-itemEvents:hover {
  transform: perspective(1000px) rotateX(3deg) rotateY(3deg);
  box-shadow: rgb(145, 145, 145) 0 0 5px 2px;
}
.play-card-itemEvents:hover .play-photo {
  overflow: hidden;
}
.play-card-itemEvents:hover .play-photo img {
  transform: scale(1.1);
}
.play-card-itemEvents h1, .play-card-itemEvents p, .play-card-itemEvents h3 {
  margin: 0;
}
.play-card-itemEvents .play-content h3 {
  text-align: center;
  padding: 0px 35px;
}
.play-card-itemEvents h1 {
  font-family: Leelawadee, serif;
}
.play-card-itemEvents .play-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  align-items: center;
}
.play-card-itemEvents p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 7;
  -webkit-box-orient: vertical;
  text-align: justify;
  font-size: 12px;
}
.play-card-itemEvents .play-photo {
  flex-grow: 0;
  border-radius: 5px;
  overflow: clip;
  /* aspect-ratio: 3/4; */
  width: 100%;
  transition: 0.2s;
}
.play-card-itemEvents .play-photo img {
  object-fit: contain;
  width: 90%;
  height: 100%;
  max-width: 100%;
  transition: 0.2s;
  display:block;
  margin:auto;
}




/* Tablet version */
@media (max-width: 1024px) {
  .play-card-itemEvents {
    width: 50%;
  }
  /* .play-card-item p {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  } */
}


@media (max-width: 768px) {
  .play-card-itemEvents .play-photo img {
    width: 100%;
    height: auto;
  }
  .play-card-itemEvents p {
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
  }

  .play-card-itemEvents {
    width: 70%;
    margin-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .play-card-itemEvents {
    width: 100%;
    margin-bottom: 15px;
  }
  .play-card-itemEvents #date {
    font-size: 0.9rem;
  }
  .play-card-itemEvents p {
    font-size: 10px;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
  }
  .play-card-itemEvents .play-content h3 {
    padding: 0px 20px;
  }
  .play-card-itemEvents {
    width: 100%;
  }
}

