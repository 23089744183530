.institucional-page {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: justify;
  font-family: "Lexend", serif;
}

@media screen and (min-width: 1024px) {
  .institucional-page {
    width: 960px;
  }
}

@media screen and (min-width: 1216px) {
  .institucional-page {
    width: 1152px;
  }
}

@media screen and (min-width: 1408px) {
  .institucional-page {
    width: 1344px;
  }
}

.institucional-page h1 {
  text-transform: uppercase;
  text-align: center;
  background-color: #291100;
  color: #e8b600;
  padding: 10px;
}

.institucional-page .inst-container {
  display: flex;
  justify-content: space-evenly;
}

.institucional-page a {
  color: #291100;
  width: 30%;
}

.institucional-page .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.institucional-page img {
  aspect-ratio: 1;
  width: 40%;
  border-radius: 100%;
  object-fit: cover;
}

/* Tablet version */
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .institucional-page {
    width: 90%;
    padding: 0 20px;
  }

  .institucional-page .inst-container {
    flex-direction: column;
    align-items: center;
  }

  .institucional-page a {
    width: 80%;
    margin-bottom: 20px;
  }

  .institucional-page img {
    width: 60%;
  }
}

/* Phone version */
@media screen and (max-width: 767px) {
  .institucional-page {
    width: 90%;
    padding: 10px;
  }

  .institucional-page .inst-container {
    flex-direction: column;
    align-items: center;
  }

  .institucional-page a {
    width: 100%;
    margin-bottom: 20px;
  }

  .institucional-page img {
    width: 80%;
  }
}

