.alt-effect{
  &:hover{
    //background-color: blue;
    transform: scale(1.1) !important;
    box-shadow: rgb(255, 0, 0) 0 0 5px 2px;

    .play-photo{
      overflow: hidden;
      img{
        transform: scale(1) !important;
      }
    }
  }
}

.noticias-page{
  .play-photo{
    aspect-ratio: inherit !important;
  }
}

.play-card-item{

  #date{
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: normal;
  }
  .more-button{
    color: black;
    text-decoration: none;
    //font-family: Koulen, serif;
    padding: 20px;

    border-radius: 10px;
    transition: .2s;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  //aspect-ratio: 5/5;
  width: 25%;



  //flex: 1;
  flex-grow: 0;
  transition: all .2s;
  //border-radius: 10px;
  //box-shadow: rgb(183, 183, 183) 0 0 5px 2px;
  &:hover{
    //background-color: blue;
    transform: perspective(1000px) rotateX(3deg) rotateY(3deg);
    box-shadow: rgb(145, 145, 145) 0 0 5px 2px;

    .play-photo{
      overflow: hidden;


      img{
        transform: scale(1.1);
      }
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;


  h1, p, h3{
    margin: 0;

  }

  h3{
    display: flex;
    align-items: center;
  }

  h1{
    font-family: Leelawadee, serif;
  }
  font-family: Lexend, serif;
  .play-content{
    display: flex;
    flex-direction: column;
    justify-content: start;
    flex: 1;
  }

  p{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 7;
    -webkit-box-orient: vertical;
    text-align: justify;
    font-size: 12px;
  }



  .play-photo{

    flex-grow: 0;
    border-radius: 5px;
    overflow: clip;
    aspect-ratio: 3/4;
    width: 100%;
    transition: 0.2s;
    img{


      object-fit: contain;
      background-color: white;
      width: 100%;
      height: 100%;
      max-width: 100%;
      transition: 0.2s;
    }
  }
}
