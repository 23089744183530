.talleres-page{

  margin: 20px auto;
  @media screen and (min-width: 1024px) {
    width: 960px;
  }
  @media screen and (min-width: 1216px) {
    width: 1152px;
  }
  @media screen and (min-width: 1408px) {
    width: 1344px;
  }
  a{
    text-decoration: none;
    color: inherit;
  }
  .title-ban {
    background-color: #291100;
    color: #e8b600;
    font-family: "Koulen", serif;
    align-self: center;
    text-align: center;
    padding: 10px;
  }
  .claves-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .clave-item{
    flex: 0 0 25%;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: black;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    h1{
      margin: 0 0 10px;
      text-align: center;
    }
    p{
      text-align: justify;
      margin: 0;
    }
    img{
      border-radius: 10px;
      object-fit: contain;
      width: 90%;
      margin-bottom: 30px;
    }
  }
  .top-images{
    height: 7rem;
    width: 100%;
    border-radius: 5px;
    background-color: black;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    img{
      aspect-ratio: 3/4;
      object-fit: cover;
      flex: 10%;
      padding: 5px;
    }
  }
  .taller-item{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: white;
    p{
      background-color: #291100;
      border-radius: 10px;
      padding: 1.5rem;
    }
    img{
      aspect-ratio: 1;
      object-fit: cover;
      width: 250px;
      border: #291100 solid 10px;
      border-radius: 100%;
    }
    a{
      background-color: #291100;
      border-radius: 10px;
      padding: 1.5rem;
    }
    h1, h5{
      padding: 0;
      margin: 0;
    }
    h5{
      margin-top: 10px;
    }
  }
}
