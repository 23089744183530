.footer {
  background-color: #e8b600;
  padding: 0.5rem 0;
  text-align: center;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto;
}
.footer .contact-info,
.footer .social-media,
.footer .copyright {
  flex: 1 0 auto;
}
.footer .contact-info p {
  margin-bottom: 0.5rem;
}
.footer .contact-info a,
.footer .copyright a {
  color: #333;
  text-decoration: none;
}
.footer .contact-info a:hover,
.footer .copyright a:hover {
  color: #007bff;
}
.footer .social-media {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.footer .social-media a {
  margin-right: 1rem;
  font-size: 1.2rem;
  color: #333;
}
.footer .social-media a:hover {
  color: #007bff;
}
.footer .social-media i {
  transition: transform 0.2s ease-in-out;
}
.footer .social-media a:hover i {
  transform: scale(1.1);
}
.footer .copyright {
  margin-top: 1rem;
  font-size: 0.8rem;
}

/* Tablet version */
@media (max-width: 768px) {
  .footer .container {
    flex-direction: column;
    align-items: center;
  }
  .footer .contact-info,
  .footer .social-media,
  .footer .copyright {
    flex: 1 0 100%;
    text-align: center;
  }
  .footer .social-media {
    margin-top: 0.5rem;
  }
}

/* Phone version */
@media (max-width: 480px) {
  .footer .container {
    padding: 0 1rem;
  }
  .footer .social-media a {
    margin-right: 0.5rem;
    font-size: 1rem;
  }
  .footer .copyright {
    font-size: 0.7rem;
  }
}

