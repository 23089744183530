/* Desktop version (default) */
.land-page .front-image {
  aspect-ratio: 4;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  font-family: "Libre Caslon Display", serif;
  color: #e8b600;
  text-shadow: black 2px 2px 3px;
  font-weight: bold;
  font-size: 30px;
}
.land-page .front-image .title {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.land-page .front-image svg {
  padding: 30px 0 20px 0;
  width: 95%;
}
.land-page .news-intro {
  text-align: center;
}
.land-page .news-intro .button-more-news {
  display: inline-block;
  margin: 15px;
  background-color: #E8B600;
  color: #291100;
  font-weight: 900;
  text-transform: uppercase; 
  font-size: 1.5vw;
  text-align: center;
  padding: 15px 10px;
}
.land-page .news-intro .button-more-news a{
  padding: 50px;
}
.land-page .news-intro a {
  text-decoration: none;
  color: inherit;
}
.land-page .news-intro .button-more-news:hover {
  background-color: #291100;
  color: #E8B600;
  box-shadow: rgb(145, 145, 145) 0 0 5px 2px;
}
.land-page .words-row {
  overflow: hidden;
  aspect-ratio: 4;
  text-align: center;
  display: flex;
}
.land-page .words-row .square {
  width: 25%;
  aspect-ratio: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  justify-content: flex-start;
}
.land-page .words-row .square h1, .land-page .words-row .square h2, .land-page .words-row .square h4 {
  text-transform: uppercase;
  text-align: center;
  padding: 0;
  margin: 5px 0;
  font-size: 1.5vw;
}
.land-page .words-row .square h5 {
  margin: 5px 0;
  font-size: 1vw;
}
.land-page .words-row .square .separador {
  width: 70%;
  height: 3px;
  background-color: #e8b600;
  content: "";
}
.land-page .words-row .square p {
  font-style: italic;
  font-size: 1.1vw;
  padding: 10px 5px;
  text-align: justify;
}
.land-page .words-row .rectangle {
  text-transform: uppercase;
  text-align: center;
  height: 100%;
  aspect-ratio: 2;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: black;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.land-page .words-row .rectangle h1, .land-page .words-row .rectangle h2 {
  width: 90%;
  color: #e8b600;
  font-size: 2.5vw;
  text-shadow: black 2px 2px 3px;
}

/* Tablet version */
@media (max-width: 1024px) {
  .land-page .front-image {
    aspect-ratio: 3;
    font-size: 25px;
  }
  .land-page .news-intro .button-more-news {
    font-size: 2vw;
  }
  .land-page .words-row .square h1, .land-page .words-row .square h2, .land-page .words-row .square h4 {
    font-size: 2vw;
  }
  .land-page .words-row .square h5 {
    font-size: 1.5vw;
  }
  .land-page .words-row .square p {
    font-size: 1.5vw;
  }
  .land-page .words-row .rectangle h1, .land-page .words-row .rectangle h2 {
    font-size: vw;
  }
}

/* Phone version */
@media (max-width: 768px) {
  .land-page .front-image {
    aspect-ratio: 1;
    font-size: 20px;
  }
  .land-page .news-intro .button-more-news {
    font-size: 3vw;
  }
  .land-page .words-row {
    flex-direction: column;
  }
  .land-page .words-row .square {
    width: 100%;
    margin-bottom: 20px;
  }
  .land-page .words-row .square h1, .land-page .words-row .square h2, .land-page .words-row .square h4 {
    font-size: 3vw;
  }
  .land-page .words-row .square h5 {
    font-size: 2vw;
  }
  .land-page .words-row .square p {
    font-size: 2vw;
  }
  .land-page .words-row .rectangle {
    width: 100%;
    aspect-ratio: auto;
  }
  .land-page .words-row .rectangle h1, .land-page .words-row .rectangle h2 {
    font-size: 4vw;
  }
}
