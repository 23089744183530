/* Desktop version (default) */
.alt-effect:hover {
  transform: scale(1.1) !important;
  box-shadow: rgb(255, 0, 0) 0 0 5px 2px;
}
.alt-effect:hover .play-photo {
  overflow: hidden;
}
.alt-effect:hover .play-photo img {
  transform: scale(1) !important;
}

.noticias-page .play-photo {
  aspect-ratio: inherit !important;
}

.play-card-item {
  width: 25%;
  flex-grow: 0;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-family: Lexend, serif;
}
.play-card-item #date {
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: normal;
}
.play-card-item .more-button {
  color: black;
  text-decoration: none;
  padding: 20px;
  border-radius: 10px;
  transition: 0.2s;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.play-card-item:hover {
  transform: perspective(1000px) rotateX(3deg) rotateY(3deg);
  box-shadow: rgb(145, 145, 145) 0 0 5px 2px;
}
.play-card-item:hover .play-photo {
  overflow: hidden;
}
.play-card-item:hover .play-photo img {
  transform: scale(1.1);
}
.play-card-item h1, .play-card-item p, .play-card-item h3 {
  margin: 0;
}
.play-card-item h3 {
  display: flex;
  align-items: center;
}
.play-card-item h1 {
  font-family: Leelawadee, serif;
}
.play-card-item .play-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 1;
}
.play-card-item p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 7;
  -webkit-box-orient: vertical;
  text-align: justify;
  font-size: 12px;
}
.play-card-item .play-photo {
  flex-grow: 0;
  border-radius: 5px;
  overflow: clip;
  /* aspect-ratio: 3/4; */
  width: 100%;
  transition: 0.2s;
}
.play-card-item .play-photo img {
  object-fit: contain;
  /* background-color: white; */
  width: 100%;
  height: 100%;
  max-width: 100%;
  transition: 0.2s;
}

/* Tablet version */
@media (max-width: 1024px) {
  .play-card-item {
    width: 50%;
  }
  /* .play-card-item p {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  } */
}

/* Phone version */
@media (max-width: 768px) {
  .play-card-item {
    width: 80%;
  }
  /* .play-card-item p {
    -webkit-line-clamp: 2;
    line-clamp: 2;
  } */
}
