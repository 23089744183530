.calendar-view {
  margin: 0 auto;
}
@media screen and (min-width: 1024px) {
  .calendar-view {
    width: 960px;
  }
}
@media screen and (min-width: 1216px) {
  .calendar-view {
    width: 1152px;
  }
}
@media screen and (min-width: 1408px) {
  .calendar-view {
    width: 1344px;
  }
}
.calendar-view img {
  width: 90%;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: black 3px 3px 10px;
  margin: 0px 20px;
}
.calendar-view .calendar-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.calendar-view .calendar-header .information {
  flex: 1;
}
.calendar-view .calendar-header .information p{
  font-size: 20px;
}
.calendar-view h1 {
  text-transform: uppercase;
  text-align: center;
}
.calendar-view .calendar-header h4 {
  font-size: 12px;
}
.calendar-view .calendar-content {
  display: flex;
  flex-direction: column;
}
.calendar-view .calendar-content p {
  white-space: pre-wrap;
  text-align: justify;
  padding: 1rem;
}
.calendar-view .boton-boletas a{
  display: block;
  text-transform: uppercase;
  cursor: pointer; 
  text-decoration: none;
  color: #291100;
  background: #e8b600;
  padding: 5px 50px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .161);
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.calendar-view .boton-boletas a:hover{
  color: #e8b600;
  background: #291100;
}
.calendar-view .calendar-content .media-content {
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  column-gap: 2rem;
  row-gap: 2rem;
}
.calendar-view .calendar-content .media-content img {
  aspect-ratio: inherit;
  max-width: 20vw;
  background-color: black;
}
.calendar-view .calendar-content .media-content video {
  border-radius: 10px;
  max-width: 60%;
}

@media screen and (max-width: 1043px) {
  .calendar-view {
    width: 100%;
    /* padding: 0 1rem; */
  }
  .calendar-view img {
    width: 90%;
    text-align: center;
  }
  .calendar-view .calendar-header {
    flex-direction: column;
    text-align: center;
  }
  .calendar-view .calendar-header .information p {
    font-size: 18px;
  }
  .calendar-view .calendar-header h4 {
    font-size: 10px;
  }
  .calendar-view .calendar-content {
    padding: 0;
  }
  .calendar-view .calendar-content p {
    padding: 0.5rem;
  }
  .calendar-view .boton-boletas a {
    padding: 5px 20px;
    font-size: 16px;
  }
  .calendar-view .calendar-content .media-content {
    padding: 1rem;
    column-gap: 1rem;
    row-gap: 1rem;
  }
  .calendar-view .calendar-content .media-content img {
    max-width: 40vw;
  }
  .calendar-view .calendar-content .media-content video {
    max-width: 100%;
  }
  .calendar-view .boton-boletas a{
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .calendar-view .calendar-header .information p {
    font-size: 16px;
  }
  .calendar-view .calendar-header h4 {
    font-size: 8px;
  }
  .calendar-view .boton-boletas a {
    padding: 5px 10px;
    font-size: 14px;
  }
  .calendar-view .calendar-header {
    flex-direction: column;
    text-align: center;
  }
  .calendar-view .calendar-content .media-content img {
    max-width: 80vw;
  }
  .calendar-view .boton-boletas a{
    font-size: 30px;
  }
}
