.header {
  position: sticky;
  top: 0;
  background-color: #f6ead4;
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header img {
  max-width: 100px;
}

ul {
  list-style-type: none;
}

.menu-desktop-only {
  display: contents;
}

.nav-bar {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  font-family: "Koulen", sans-serif;
  width: 100%;
  justify-content: space-evenly;
  list-style: none;
  padding: 0;
  background-color: #E8B600;
  margin: 0;
}

.nav-bar a {
  font-size: 15px;
  text-decoration: none;
  color: inherit;
  flex: 1;
  display: contents;
}

.nav-bar .icon {
  display: none;
}

.nav-bar h1 {
  margin: 0;
  text-align: center;
  font-size: 2vw;
}

.nav-bar li {
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #E8B600;
  color: #291100;
  height: 60px;
  display: flex;
}

.nav-bar li:hover {
  background-color: #291100;
  color: #E8B600;
}

.nav-bar .dropdown {
  float: left;
  overflow: hidden;
}

.nav-bar .dropdown .dropbtn {
  border: none;
  outline: none;
  background-color: inherit;
  font-family: inherit;
  /* Important for vertical align on mobile phones */
  margin: 0;
  /*Important for vertical align on mobile phones */
}

.nav-bar .dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  background-color: #f9f9f9;
  z-index: 1;
}

.nav-bar .dropdown-content a {
  float: none;
  color: black;
  padding: 5px 2px;
  margin: 0;
  text-decoration: none;
  display: block;
  text-align: left;
}

.nav-bar .dropdown-content a:hover {
  background-color: #ddd;
}

.nav-bar .dropdown:hover .dropdown-content {
  display: block;
}

/* .nav-bar h1 {
  text-overflow: fade;
  white-space: nowrap;
} */

/* Tablet version */
@media (max-width: 768px) {
  .nav-bar {
    flex-direction: column;
    display: none;
  }

  .nav-bar li {
    width: 100%;
    text-align: center;
  }

  .nav-bar h1 {
    font-size: 7vw;
  }
}


/* Burger button styles */
.burger-button {
  display: none;
  /* Hidden by default */
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 200;
}

.burger-button i {
  color: #291100;
}

/* Show burger button only on phone and tablet versions */
@media (max-width: 768px) {
  .burger-button {
    display: block;
  }
}

/* Menu overlay styles */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 150;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-overlay .nav-bar {
  flex-direction: column;
  background-color: #E8B600;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  filter: opacity(0.9);
}

.menu-overlay .nav-bar .dropdown:hover {
  background-color: #291100;
  color: #E8B600;
  width: 100%;
}

.menu-overlay .nav-bar .dropdown:hover .dropbtn {
  background-color: inherit;
  color: inherit;
  width: 100%;
  display: block;
}

.menu-overlay .nav-bar li {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  text-decoration: none;
}

.menu-overlay .nav-bar .dropdown-content {
  position: relative;
  background-color: #E8B600;
  padding: 0;
  top: 0;
  width: 100%;
  /* Ensure it spans the full width of the parent */
  box-sizing: border-box;
  /* Include padding and border in width */
  text-align: center;
  /* Center-align the content */
  columns: 2;
  /* número de columnas deseadas */
  column-gap: 20px;
  /* espacio entre columnas */
}