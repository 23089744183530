.news-view{
  @media screen and (min-width: 1024px){
    width: 960px;
  }
  @media screen and (min-width: 1216px){
    width: 1152px;
  }
  @media screen and (min-width: 1408px){
    width: 1344px;
  }
  img{
    aspect-ratio: 188/157;
    object-fit: contain;
    max-width: 25vw;
    border-radius: 10px;
    box-shadow: black 3px 3px 10px;
  }
  margin: 0 auto;
  .news-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    .information{
      flex: 1;
    }
    h1{
      text-transform: uppercase;
      text-align: center;
    }
    h4{
      font-size: 12px;
    }
  }
  .news-content{
    display: flex;
    flex-direction: column;
    p{
      white-space: pre-wrap;
      text-align: justify;
      padding: 1rem;
    }
    .media-content{
      padding: 2rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      column-gap: 2rem;
      row-gap: 2rem;
      img{
        aspect-ratio: inherit;
        max-width: 20vw;
        background-color: black;
      }
      video{
        border-radius: 10px;
        max-width: 60%;
      }
    }
  }
}
