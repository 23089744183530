.play-view{
  //background-color: #E48618;
  padding-top: 3rem;
  margin-top: 0;
  display: block;
  .play-banner-wrapper{
    overflow: hidden;
    position: relative;
    display: block;
    .play-banner-container{

      border-left: 2px solid #000;
      border-right: 2px solid #000;
      margin: 0 auto;
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      position: relative;
      width: auto;

      @media screen and (min-width: 1024px){
        max-width: 960px;
      }
      @media screen and (min-width: 1216px){
        max-width: 1152px;
      }
      @media screen and (min-width: 1408px){
        max-width: 1344px;
      }


      &:after{
        content: '';
        display: block;
        width: 10rem;
        height: 100%;
        position: absolute;
        top: 0;
        left: calc(100% + 2px);
        background: linear-gradient(to right, rgba(0,0,0,.65) 0, transparent 50%);
      }
      &:before{
        content: '';
        display: block;
        width: 10rem;
        height: 100%;
        position: absolute;
        top: 0;
        right: calc(100% + 2px);
        background: linear-gradient(to left, rgba(0,0,0,.65) 0, transparent 50%);
      }

      .play-banner-poster{
        flex: none;
        width: 25%;
        figure{
          display: block;
          border-radius: 0 10px 10px 0;
          overflow: hidden;
          line-height: 0;
          position: relative;
          z-index: 1;
          //transform: scale(1.15);
          //left: 7.5%;
          margin: 0;
          padding: 0;

          img{
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            min-width: 100%;
            max-width: 100%;
          }
          aspect-ratio: auto 360/550;
          &:after{
            content: "";
            display: block;
            padding-bottom: 100%;
          }
        }
      }
      .play-banner-carousel{
        width: 73%;
        background-color: black;
        border-radius: 10px 0 0 10px;
        overflow: hidden;
      }
    }
  }
}

.play-info{
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  @media screen and (min-width: 1024px){
    max-width: 960px;
  }
  @media screen and (min-width: 1216px){
    max-width: 1152px;
  }
  @media screen and (min-width: 1408px){
    max-width: 1344px;
  }


  p{
    text-align: justify;
  }
  .play-datasheet{
    width: 25%;
    margin-top: 2.5rem;
    p{
      margin: 0;
    }
    .text{
      display: grid;
      grid-row-gap: 20px;
    }
  }

  .info{
    margin: 2.5rem 0;

    width: 73%;
    display: flex;
    flex-direction: column;
    video{
      border-radius: 10px;
    }
    .trailer{
      display: flex;
      justify-content: center;
    }
  }

  .play-synopsis{

    .text{
      background-color: #e8b600;
      white-space: pre-wrap;
    }
  }
  .text{
    padding: 30px;
    text-align: justify;
    border-radius: 10px;
  }

  .item{
    //background-color: rebeccapurple;
    //margin: 30px 0 0 0;
  }

  h4{
    margin: 0;
  }
}
