.institucional-page{
  @media screen and (min-width: 1024px){
    width: 960px;
  }
  @media screen and (min-width: 1216px){
    width: 1152px;
  }
  @media screen and (min-width: 1408px){
    width: 1344px;
  }
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: justify;
  font-family: "Lexend", serif;
  h1{
    text-transform: uppercase;
    text-align: center;
    background-color: #291100;
    color: #e8b600;
    padding: 10px;
  }
  .inst-container{
    display: flex;
    justify-content: space-evenly;
  }
  a{
    color: #291100;
    width: 30%;
  }
  .item{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  img{
    aspect-ratio: 1;
    width: 40%;
    border-radius: 100%;
    object-fit: cover;

  }
}