.creaciones-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.creaciones-page h1 {
  background-color: #291100;
  color: #e8b600;
  font-family: "Koulen", serif;
  align-self: center;
  text-align: center;
  padding: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .creaciones-page h1 {
    width: 80%;
  }
}
@media screen and (min-width: 1024px) {
  .creaciones-page h1 {
    width: 960px;
  }
}
@media screen and (min-width: 1216px) {
  .creaciones-page h1 {
    width: 1152px;
  }
}
@media screen and (min-width: 1408px) {
  .creaciones-page h1 {
    width: 1344px;
  }
}

.card-container {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 15px;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .card-container {
    flex-direction: column;
    padding: 0 10px;
    align-items: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .card-container {
    max-width: 90%;
    padding: 0 20px;
  }
}
@media screen and (min-width: 1024px) {
  .card-container {
    max-width: 960px;
  }
}
@media screen and (min-width: 1216px) {
  .card-container {
    max-width: 1152px;
  }
}
@media screen and (min-width: 1408px) {
  .card-container {
    max-width: 1344px;
  }
}
