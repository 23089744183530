@charset "UTF-8";
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Opacidad del fondo */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Asegura que esté por encima de otros elementos */
}
.modal .modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
}
.modal .close-container {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.modal .close-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8); /* Color y opacidad del círculo */
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal .close {
  font-size: 24px; /* Tamaño de la x */
  color: #333; /* Color de la x */
  transition: color 0.3s; /* Transición de color suave */
}
.modal .close:hover {
  color: #aaa; /* Color de la x al pasar el mouse */
}
.modal img {
  max-width: 100%;
  max-height: 80vh; /* Limita la altura de la imagen */
  display: block;
  margin: 0 auto;
}

