.history-page {
  margin: 0 auto;
  font-family: "Lexend", serif;
}
@media screen and (min-width: 1024px) {
  .history-page {
    width: 960px;
  }
}
@media screen and (min-width: 1216px) {
  .history-page {
    width: 1152px;
  }
}
@media screen and (min-width: 1408px) {
  .history-page {
    width: 1344px;
  }
}

.history-page .portada-image {
  width: 50%;
  height: 50%;
  border-radius: 10px;
  aspect-ratio: inherit;
  margin: 0 auto;
}

.history-page h1 {
  font-size: 2.5vw;
  text-align: center;
  background-color: #291100;
  color: #e8b600;
  font-family: "Koulen", serif;
}
.history-page ul {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 0;
}
.history-page li {
  display: flex;
  justify-content: space-evenly;
  align-content: stretch;
  list-style: none;
  align-items: center;
  margin: 0;
  padding: 10px;
  text-align: justify;
}
.history-page li:nth-child(even) {
  justify-self: end;
  align-self: end;
}
.history-page li p {
  flex: 1;
  margin: 0 auto;
  font-size: 1.2vw;
  background-color: #e8b600;
  padding: 20px;
  border-radius: 20px;
}
.history-page li h4 {
  display: inline;
}
/* @media screen and (max-width: 1024px) {
  .history-page li {
    flex-direction: column;
  }
} */
.circular-image {
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 100%;
  width: 20%;
  height: 100%;
  margin: 0 30px;
}
.history-page .links {
  padding: 30px;
  display: flex;
  justify-content: space-evenly;
}
.history-page .links a {
  font-size: 1.5vw;
  color: #291100;
}

@media screen and (min-width: 768px) and (max-width: 1220px) {
  .history-page li p {
    font-size: 1.5vw;
  }
  .circular-image {
    width: 30%;
    margin: 10px;
  }
}

/* Tablet version */
@media screen and (max-width: 768px) {
  .history-page h1 {
    font-size: 4vw;
  }
  .history-page li p {
    font-size: 2.5vw;
    padding: 15px;
  }
  .history-page .links a {
    font-size: 3vw;
  }
  .circular-image {
    width: 50%;
    margin: 10px;
  }
  .history-page .portada-image{
    width: 70%;
    height: 70%;
    border-radius: 0;
    border-radius: 10px;
    aspect-ratio: 1;
  }
  .history-page li {
    flex-direction: column;
  }
}

/* Phone version */
@media screen and (max-width: 480px) {
  .history-page h1 {
    font-size: 5vw;
  }
  .history-page li p {
    font-size: 3.5vw;
    padding: 10px;
  }
  .history-page .links a {
    font-size: 4vw;
  }
  .circular-image {
    width: 90%;
    margin: 10px;
  }
  .history-page .portada-image{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    aspect-ratio: inherit;
  }
  .history-page li {
    flex-direction: column;
  }
}
