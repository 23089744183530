.nosotros-page {
  display: flex;
  margin: 30px auto;
}
@media screen and (min-width: 1024px) {
  .nosotros-page {
    width: 960px;
  }
}
@media screen and (min-width: 1216px) {
  .nosotros-page {
    width: 1152px;
  }
}
@media screen and (min-width: 1408px) {
  .nosotros-page {
    width: 1344px;
  }
}
.nosotros-page .info-banner {
  width: 50%;
  display: flex;
  align-items: center;
}
.nosotros-page .info-banner .info-photo {
  margin: 20px;
}
.nosotros-page .info-banner img {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 10px;
  object-fit: cover;
}

